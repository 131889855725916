import React from 'react';
import img from '../assets/logo.jpg';
import { NavLink } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="pt-4 sm:pt-10 lg:pt-12">
            <footer className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="mb-16 grid grid-cols-2 gap-12 border-t pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
                    <div className="col-span-full lg:col-span-2">
                        <div className="mb-4 lg:-mt-2">
                            <NavLink to="/" className="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
                                <img src={img} height={50} width={50} alt="Britcybersol Logo"/>
                                <p className="brand">Britcybersol</p>
                            </NavLink>
                        </div>

                        <p className="mb-6 text-gray-500 sm:pr-8">
                        High performance cloud servers on enterprise infrastructure with KVM virtualization, full access to our deployment API for advanced developer and scaling operations                        </p>

                        <div className="flex gap-4">
                            <a href="#" target="_blank" rel="noopener noreferrer" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                            </a>

                            <a href="#" target="_blank" rel="noopener noreferrer" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                </svg>
                            </a>

                            <a href="#" target="_blank" rel="noopener noreferrer" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                </svg>
                            </a>

                            <a href="#" target="_blank" rel="noopener noreferrer" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.544-1.38-1.33-1.748-1.33-1.748-1.089-.742.084-.726.084-.726 1.206.085 1.84 1.236 1.84 1.236 1.073 1.826 2.809 1.3 3.49.995.108-.778.42-1.3.766-1.598-2.66-.304-5.47-1.33-5.47-5.927 0-1.313.469-2.381 1.235-3.22-.124-.304-.536-1.527.118-3.181 0 0 1.008-.322 3.303 1.236.953-.265 1.979-.398 3-.403 1.021.005 2.048.139 3.003.403 2.288-1.558 3.28-1.236 3.28-1.236.653 1.654.253 2.878.129 3.182.763.839 1.232 1.908 1.232 3.221 0 4.606-2.815 5.621-5.484 5.918.433.373.822 1.111.822 2.23v3.314c0 .317.189.691.799.575 4.776-1.603 8.207-6.089 8.207-11.386 0-6.627-5.373-12-12-12z" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className="hidden md:block md:col-span-2 lg:col-span-1">
                        <h3 className="mb-5 text-sm font-semibold text-gray-900 dark:text-white">About</h3>
                        <ul className="space-y-4 text-gray-500">
                            <li><NavLink to="/faq" className="hover:underline">About Us</NavLink></li>
                         
                            <li><NavLink to='/contact' className="hover:underline">Contact</NavLink></li>

                        </ul>
                    </div>

                    <div className="hidden md:block md:col-span-2 lg:col-span-1">
                        <h3 className="mb-5 text-sm font-semibold text-gray-900 dark:text-white">Services</h3>
                        <ul className="space-y-4 text-gray-500">
                            <li><NavLink to="/blogs" className="hover:underline">Blogs</NavLink></li>
                            <li><NavLink to="/product-web" className="hover:underline">Web Hosting</NavLink></li>
                            
                        </ul>
                    </div>

                    <div className="hidden md:block md:col-span-2 lg:col-span-1">
                        <h3 className="mb-5 text-sm font-semibold text-gray-900 dark:text-white">Legal</h3>
                        <ul className="space-y-4 text-gray-500">
                            <li><NavLink to="/terms" className="hover:underline">Terms of Service</NavLink></li>
                            <li>            <NavLink to="/FAQ" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">FAQ</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="py-4 text-center text-sm text-gray-500">
                    <span className="block sm:inline">© 2024 Britcybersol. All rights reserved.</span>
                </div>
            </footer>
        </div>
    );
}
