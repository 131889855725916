import React, { useEffect, useState } from 'react';
import img from '../assets/logo.jpg';
import { NavLink } from 'react-router-dom';
export default function Header() {
  // State to control the visibility of the mobile menu
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  
  // Function to toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    console.log("toggle");
  };

  return (
    <div className=" lg:pb-12">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <header className="flex items-center justify-between py-4 md:py-8">
          <NavLink to="/" className="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
          <img src={img} height={50} width={50}/>
          <p  className='brand'>  Britcybersol</p>
          </NavLink>

          {/* Desktop Navigation */}
          <nav className=" hidden gap-12  lg:flex">
            <NavLink to="/" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">Home</NavLink>
            <NavLink to="/services" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">Service</NavLink>
            <NavLink to="/products" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">Products</NavLink>

            <NavLink to="/network" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">Network</NavLink>
            <NavLink to='/user-sign' className="inline-block rounded-lg bg-orange-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-grey-300 transition duration-100 hover:bg-orange-600 focus-visible:ring active:bg-indigo-700 md:text-base">Sign up</NavLink>
          </nav>

          {/* Mobile Menu Button */}
                
<label
  for="nav_bar_icon"
  class="w-9 h-10 cursor-pointer flex flex-col items-center justify-center space-y-1.5 lg:hidden"
>
  <input id="nav_bar_icon" type="checkbox" class="hidden peer"     onClick={toggleMenu}/>
  <div
    class="w-2/3 h-1.5 bg-orange-400 rounded-lg transition-all duration-300 origin-right peer-checked:w-full peer-checked:rotate-[-30deg] peer-checked:translate-y-[-5px]"
  ></div>
  <div
    class="w-full h-1.5 bg-orange-400 rounded-lg transition-all duration-300 origin-center peer-checked:rotate-90 peer-checked:translate-x-4"
  ></div>
  <div
    class="w-2/3 h-1.5 bg-orange-400 rounded-lg transition-all duration-300 origin-right peer-checked:w-full peer-checked:rotate-[30deg] peer-checked:translate-y-[5px]"
  ></div>
</label>
        </header>

        {/* Mobile Navigation */}
        {!isMenuOpen && (
          <ul className="flex flex-col gap-4 mt-4  mb-1">
            <li>
              <NavLink to="/" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/services" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/products" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">
                Products
              </NavLink>
            </li>
            <li>
              <NavLink to="/network" className="inline-block rounded-lg px-4 py-3 text-center text-sm font-semibold text-gray-100 outline-none ring-grey-300 transition duration-100 hover:text-orange-500 focus-visible:ring active:text-indigo-600 md:text-base">
              Network
              </NavLink>
            </li>
            
            <li>
              <NavLink to="/user-sign" className="inline-block rounded-lg bg-orange-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-grey-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">
                Sign up
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
