import React, { useEffect, useState } from 'react';
import img from '../assets/lgo.png'

export default function Genin() {
    const [invoiceData, setInvoiceData] = useState(null);

    useEffect(() => {
        // Retrieve the stored invoice data from localStorage
        const storedInvoiceData = localStorage.getItem('invoiceData');
        if (storedInvoiceData) {
            setInvoiceData(JSON.parse(storedInvoiceData));
        }
    }, []);

    if (!invoiceData) {
        return <p>Loading...</p>;
    }

    return (
        <div className="py-8 sm:py-12 lg:py-16">
            <div className="mx-auto max-w-screen-md px-4 md:px-8 bg-white shadow-lg rounded-lg">
                <div className="flex items-center justify-between py-4 px-6 border-b border-gray-300">
                    <img src={img} alt="Company Logo" className="w-32 h-auto" />
                    <h2 className="text-2xl font-bold text-gray-800">Invoice</h2>
                </div>

                <div className="p-6">
                    <div className="mb-6">
                        <h3 className="text-lg font-semibold text-gray-700">Customer Details</h3>
                        <div className="mt-2">
                            <div><strong>Name:</strong> {invoiceData.name}</div>
                            <div><strong>Email:</strong> {invoiceData.email}</div>
                        </div>
                    </div>

                    <div className="mb-6">
                        <h3 className="text-lg font-semibold text-gray-700">Invoice Details</h3>
                        <div className="mt-2">
                            <div><strong>Plan:</strong> {invoiceData.selectedPlan}</div>
                            <div><strong>Sub-Plan:</strong> {invoiceData.selectedSubPlan}</div>
                            <div><strong>Duration:</strong> {invoiceData.duration} Months</div>
                            <div><strong>Price:</strong> {invoiceData.price}</div>
                        </div>
                    </div>

                    <div className="border-t border-gray-300 pt-4 text-right">
                        <strong>Total Amount:</strong> <span className="text-xl font-bold">{invoiceData.price}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
